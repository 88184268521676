import initializeLanguageSwitchers from './languageSwitchers.js';
import handleScroll from './scrollHandler.js';
import '../styles/main.css';

let scrollPosition = { top: 0, left: 0 };

function saveScrollPosition() {
  scrollPosition.top = window.scrollY || document.documentElement.scrollTop;
  scrollPosition.left = window.scrollX || document.documentElement.scrollLeft;
}

function restoreScrollPosition() {
  window.scrollTo(scrollPosition.left, scrollPosition.top);
  
  requestAnimationFrame(() => {
    const modal = document.getElementById('videoModal');
    const isMobile = window.innerWidth <= 768;
    
    // Проверяем, активна ли модалка и корректно ли отображается
    if (isMobile && modal.style.display === "flex" && !document.fullscreenElement) {
      closeModal(modal);
    } else if (modal.style.display === "flex") {
      updateModalPosition(modal);
    }
  });
}

document.addEventListener('DOMContentLoaded', function() {
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  const modal = document.getElementById('videoModal');

  initializeLanguageSwitchers();
  setInitialLanguage();
  resetVisibleElements();

  const playButton = document.querySelector('.playVideoButton');
  if (playButton) {
    playButton.addEventListener('click', playVideo);
  }

  const closeBtn = document.querySelector('.close');
  if (closeBtn) {
    closeBtn.addEventListener('click', () => closeModal(modal));
  }

  // Закрытие модалки по клику на фон или по событию touch (для мобильных устройств)
  window.addEventListener('click', (event) => {
    if (event.target === modal) {
      closeModal(modal);
    }
  });

  // Добавляем touch-событие для мобильных устройств
  window.addEventListener('touchstart', (event) => {
    if (event.target === modal) {
      closeModal(modal);
    }
  });

  document.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', adjustTrailerVideoSize);
  document.addEventListener('fullscreenchange', restoreScrollPosition);
});

function setInitialLanguage() {
  const engLanguageImage = document.querySelector('.engLanguage');
  const cnLanguageImage = document.querySelector('.cnLanguage');
  const p2eTextEng = document.querySelector('.p2eTextEng');
  const p2eTextCn = document.querySelector('.p2eTextCn');
  const telegramChannelLink = document.getElementById('telegramChannelLink');

  engLanguageImage.src = 'assets/languageSwitcher/engSwitchOn.svg';
  cnLanguageImage.src = 'assets/languageSwitcher/cnSwitchOff.svg';
  p2eTextEng.classList.remove('hidden');
  p2eTextCn.classList.add('hidden');

  const userLang = navigator.language || navigator.userLanguage;
  const lang = userLang.startsWith('zh') ? 'cn' : 'en';

  document.documentElement.lang = lang;

  telegramChannelLink.href = lang === 'cn'
    ? 'https://t.me/DurianoChina'
    : 'https://t.me/DurianoTeam';
}

function resetVisibleElements() {
  const visibleElements = document.querySelectorAll('.visible');
  visibleElements.forEach(element => {
    element.classList.remove('visible');
  });
}

function playVideo() {
  const modal = document.getElementById('videoModal');
  const modalContent = document.querySelector('.modal-content');

  // Показываем модалку
  modal.style.display = "flex";
  modalContent.classList.remove('close');
  modalContent.classList.add('open');
  document.body.style.overflow = 'hidden';

  // Создаем iframe элемент динамически
  const iframe = document.createElement('iframe');
  iframe.width = '100%';
  iframe.height = '100%';
  iframe.src = "https://www.youtube.com/embed/QUOkILRC_kc?enablejsapi=1&controls=1&rel=0&modestbranding=1&playsinline=1&autoplay=1";
  iframe.style.border = 'none';
  iframe.allow = "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay";
  iframe.allowFullscreen = true;

  // Добавляем iframe в модальное окно
  modalContent.innerHTML = ''; // Очистка перед добавлением нового iframe
  modalContent.appendChild(iframe);

  saveScrollPosition();
  updateModalPosition(modal);
}

function closeModal(modal) {
  modal.style.display = "none";
  document.body.style.overflow = '';

  // Очищаем содержимое модального окна (iframe) при его закрытии
  const modalContent = document.querySelector('.modal-content');
  modalContent.innerHTML = '';  // Удаление содержимого
}

function updateModalPosition(modal) {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
  
  modal.style.top = `${scrollTop + window.innerHeight / 2}px`;
  modal.style.left = `${scrollLeft + window.innerWidth / 2}px`;
  modal.style.transform = "translate(-50%, -50%)";
}

function adjustTrailerVideoSize() {
  const iframe = document.getElementById('trailerVideoIframe');
  const windowWidth = window.innerWidth;

  const { width, height } = getVideoDimensions(windowWidth);

  iframe.setAttribute('width', width);
  iframe.setAttribute('height', height);
}

function getVideoDimensions(windowWidth) {
  if (windowWidth <= 430) {
    return { width: '278', height: '159.7', scale: 0.2688588007736944 };
  } else if (windowWidth <= 1440) {
    return { width: '775.5', height: '445.5', scale: 0.75 };
  } else {
    return { width: '1034', height: '594', scale: 1 };
  }
}
